<template>
  <v-dialog
      v-model="dialog"
      @click:outside="$emit('closeTalentDialogClosed', true)"
      max-width="60vh"
      :persistent="loading"
  >
    <v-card>
      <v-card-title>Create User & Talent Profile</v-card-title>
      <v-card-subtitle>A User account will always be created in the system. <v-switch v-model="hasProfile" label="Profile already created"></v-switch></v-card-subtitle>
      <v-card-text v-if="hasProfile">
        <v-autocomplete
            v-model="talentAssociateProfile.talentId"
            :items="talentData"
            item-text="email"
            item-value="id"
            label="Talent *"
            chips
            hint="Select the profile that should be associated with this submission & user"
            autocomplete="off"
            persistent-hint
        >

        </v-autocomplete>
        <v-checkbox
            disabled
            v-model="talentAssociateProfile.sendNotification"
            label="Send notification to talent that a user account has been created, with instructions on how to login"
            persistent-hint
            hint="Disabled until talent portal is finished, user account will still be created though."
        ></v-checkbox>
      </v-card-text>
      <v-card-text v-else>
        <v-checkbox v-model="talentCreateProfile.createProfile" label="Automatically create talent profile and link this submission"></v-checkbox>
        <v-autocomplete
            v-if="talentCreateProfile.createProfile"
            v-model="talentCreateProfile.primarySkillId"
            :items="skillList"
            item-text="title"
            item-value="id"
            label="Primary Skill *"
            hint="Select the primary skill of this talent."
            persistent-hint
            autocomplete="off"
        ></v-autocomplete>
        <v-text-field v-if="talentCreateProfile.createProfile" type="number" v-model="talentCreateProfile.primarySkillYearsOfExperience" label="Primary Skill Years of Experience"></v-text-field>
        <v-checkbox
            disabled
            v-model="talentCreateProfile.sendNotification"
            label="Send notification to talent that a user account has been created, with instructions on how to login"
            persistent-hint
            hint="Disabled until talent portal is finished, user account will still be created though."
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn color="danger" @click="$emit('closeTalentDialogClosed', true)" :loading="loading">Close (without saving, or click outside)</v-btn>
        <v-btn color="primary" :loading="loading" @click="save">Save & Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "MatchProfileToTalentSubmissionDialog",

  props: {
    talentSubmissionData: {
      type: Object,
      required: true
    },

    dialog: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    talentSubmissionDataId() {
      return this.talentSubmissionData.id
    }
  },

  mounted() {
    this.getTalent()
    this.getSkills()
  },

  data() {
    return {
      hasProfile: false,
      talentCreateProfile: {
        createProfile: false,
        sendNotification: false,
        primarySkillId: null,
        primarySkillYearsOfExperience: 3,
        talentSubmissionId: this.talentSubmissionData.id
      },
      talentAssociateProfile: {
        talentId: null,
        createProfile: false,
        sendNotification: false,
        primarySkillId: 1,
        primarySkillYearsOfExperience: 3,
        talentSubmissionId: this.talentSubmissionData.id
      },
      loading: false,
      talentData: [],
      skillList: [],
      submitting: false,
    }
  },

  methods: {
    save() {
      // This data isn't reactive, hence we set it before we send it off.
      this.talentCreateProfile.talentSubmissionId = this.talentSubmissionData.id
      this.talentAssociateProfile.talentSubmissionId = this.talentSubmissionData.id

      if (this.hasProfile === false) {
        let self = this
        self.submitting = true

        axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(() => {
          axios.post(`${this.$store.state.apiUrl}/api/talent-user/create`, self.talentCreateProfile, this.$store.state.headers)
              .then(function () {
                self.$store.commit("displaySnackbar", {
                  message: "Profile successfully created & submission closed",
                  color: "info"
                })
                self.submitting = false
                self.$emit("submissionClosed", self.talentSubmissionData.id)
              })
              .catch(function (response) {
                self.$store.commit("displaySnackbar", {
                  message: "Profile failed to save: " + response.response.data,
                  color: "danger"
                })
                self.submitting = false
              })
        });
      }
      else {
        let self = this
        self.submitting = true

        axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(() => {
          axios.post(`${this.$store.state.apiUrl}/api/talent-user/associate`, self.talentAssociateProfile, this.$store.state.headers)
              .then(function () {
                self.$store.commit("displaySnackbar", {
                  message: "Profile successfully associated & submission closed",
                  color: "info"
                })
                self.submitting = false
                self.$emit("submissionClosed", self.talentSubmissionData.id)
              })
              .catch(function (response) {
                self.$store.commit("displaySnackbar", {
                  message: "Profile failed to associate: " + response.response.data,
                  color: "danger"
                })
                self.submitting = false
              })
        });
      }
    },

    getTalent() {
      let self = this
      axios.get(`${this.$store.state.apiUrl}/api/talent`, this.$store.state.headers)
          .then(function (response) {
            self.talentData = response.data
          })
    },

    getSkills() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/skills`, this.$store.state.headers)
          .then(function (response) {
            self.skillList = response.data
          });
    },
  }
}
</script>

<style scoped>

</style>
