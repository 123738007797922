<template>
  <div style="max-height: 85vh; overflow-y: auto">
    <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
    <v-col v-for="(item, i) in submissionData" :key="`${i}`">
      <v-lazy :options="{threshold: .5}" :min-height="200" transition="fade-transition">
        <v-card shaped outlined>
          <v-card-title>
            <v-badge v-if="item.color === 'green' ? true : item.color === 'red'" :offset-x="15" :offset-y="-15" :color="item.color === 'green' ? 'green' : item.color === 'red' ? 'red' : undefined"></v-badge>
            {{item.name}} <span>{{item.stage === 'closed' ? '*closed' : ''}}</span>
            <v-spacer></v-spacer>
            <h5>({{ timeDiffDates(new Date(item.updated_at), new Date()) }})</h5>
            <v-spacer></v-spacer>
            <a v-if="!benchCard" @click="putColor(item, 'green')" style="color: green"> <u>✓</u> </a>
             &nbsp;
            <a v-if="!benchCard" @click="putColor(item, 'red')" style="color: red"> <u>x</u> </a>
             &nbsp;
            <a @click="moreInfoClicked(item)" style="font-size: small"> <u>more info</u></a>
          </v-card-title>
          <v-card-subtitle>
            <a @click="clipboardCopy(item.email)">{{item.email}}</a>, {{item.json.hasOwnProperty('location') ? item.json.location.hasOwnProperty('country') ? item.json.location.country : '' : ''}}
          </v-card-subtitle>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col>
                <b>English Level: </b>
                <v-chip :color="formatLanguageColor(item.json.englishLevel)" small>
                  {{item.json.englishLevel}}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-for="(skill, j) in formatSkillsExperience(item.json.experiences)" :key="`skillbadge-${j}`" style="padding: 1px;">
                <v-chip
                    :color="skill.color"
                    label
                    x-small
                >
                  {{ skill.skill.toUpperCase() }}: {{ skill.years }}y, {{ skill.level }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-chip x-small @click="scoreUpdateClicked(item)">/score: <b>{{item.slashdev_score}}</b></v-chip>
            <v-chip x-small @click="scoreUpdateClicked(item)">STscore: <b>{{item.skill_test_score}}</b></v-chip>
            <v-chip x-small @click="scoreUpdateClicked(item)">ELscore: <b>{{item.english_level}}</b></v-chip>
            <v-chip x-small @click="scoreUpdateClicked(item)">Rate: <b>{{item.rate}}</b></v-chip>
            <v-chip x-small v-if="item.skill_test_skill">PSkill: <b>{{item.skill_test_skill}}</b></v-chip>
          </v-card-actions>
          <v-card-actions v-if="item.skill_test_date || item.interview_date || item.last_reminded_notification">
            <v-chip x-small v-if="item.skill_test_date">STdate: {{item.skill_test_date}}</v-chip>
            <v-chip x-small v-if="item.interview_date">Idate: {{item.interview_date}}</v-chip>
            <v-chip x-small v-if="item.last_reminded_notification">Reminded: {{item.last_reminded_notification}}</v-chip>
          </v-card-actions>
          <v-card-actions v-if="item.tags">
            <v-chip
                x-small
                color="primary"
                v-for="(tag, i) in item.tags"
                :key="i"
            >
              {{tag.tag}}
            </v-chip>
            <v-chip x-small color="success" @click="linkTagClicked(item)">+Tag</v-chip>
          </v-card-actions>
          <v-card-actions v-if="item.bench">
            <v-chip
                x-small
                color="secondary"
                v-for="(bench, i) in item.bench"
                :key="i"
            >
              {{bench.title}}
            </v-chip>
            <v-chip x-small color="success" @click="linkBenchClicked(item)">+Bench</v-chip>
          </v-card-actions>
          <v-card-actions>
            <v-btn v-if="item.json.hasOwnProperty('portfolio') ? item.json.portfolio.linkedin : false" icon :href="item.json.portfolio.linkedin" target="_blank">
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
            <v-btn v-if="item.json.hasOwnProperty('portfolio') ? item.json.portfolio.github : false" icon :href="item.json.portfolio.github" target="_blank">
              <v-icon>mdi-github</v-icon>
            </v-btn>
            <v-btn v-if="item.json.hasOwnProperty('portfolio') ? item.json.portfolio.portfolio : false" icon :href="item.json.portfolio.portfolio" target="_blank">
              <v-icon>mdi-web</v-icon>
            </v-btn>
            <v-btn icon @click="notesButtonClicked(item)" :color="item.notes ? 'green' : undefined">
              <v-icon>mdi-text</v-icon>
            </v-btn>
            <v-btn v-if="item.attachment" icon :href="`${spacesUrl}${item.attachment ? item.attachment.filepath : ''}`" target="_blank">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn v-if="item.skill_test_skill && item.stage === 'skill test'" icon @click="putReminder(item)">
              <v-icon>mdi-mail</v-icon>
            </v-btn>
            <v-btn v-if="item && !benchCard" icon @click="confirmDelete(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn text v-if="!benchCard" @click="putData(item, 'denied')">
              DENY
            </v-btn>
            <v-spacer/>
            <v-menu
                bottom
                left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="!benchCard"
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="putData(item, 'screening')">
                  <v-list-item-title>screening</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'js')">
                  <v-list-item-title>--- JS</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'nodejs')">
                  <v-list-item-title>--- NodeJS</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'vue')">
                  <v-list-item-title>--- Vue</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'react')">
                  <v-list-item-title>--- React</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'react-native')">
                  <v-list-item-title>--- React Native</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'angular')">
                  <v-list-item-title>--- Angular</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'php')">
                  <v-list-item-title>--- PHP</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'python')">
                  <v-list-item-title>--- Python</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'swift')">
                  <v-list-item-title>--- Swift/iOS</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'android')">
                  <v-list-item-title>--- Android</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'java')">
                  <v-list-item-title>--- Java</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'csharp')">
                  <v-list-item-title>--- C#/.NET</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'go')">
                  <v-list-item-title>--- Go</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'dart')">
                  <v-list-item-title>--- Dart/Flutter</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'ruby')">
                  <v-list-item-title>--- Ruby (Rails)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'c++')">
                  <v-list-item-title>--- C++</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'skill test', 'sql')">
                  <v-list-item-title>--- SQL</v-list-item-title>
                </v-list-item>
                <v-list-item disabled @click="putData(item, 'skill test')">
                  <v-list-item-title>skill test ---</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'network pool')">
                  <v-list-item-title>network pool</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'onboarding')">
                  <v-list-item-title>onboarding & contracts</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'non-responsive')">
                  <v-list-item-title>non responsive</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'denied')">
                  <v-list-item-title>denied</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'interview denied')">
                  <v-list-item-title>denied after interview</v-list-item-title>
                </v-list-item>
                <v-list-item @click="putData(item, 'no email denied')">
                  <v-list-item-title>no email denied</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$emit('closeTalentInit', item)">
                  <v-list-item-title>closed</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-lazy>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ScreeningCard",

  computed: {
    spacesUrl() {
      return process.env.VUE_APP_DO_SS_FULL_URL
    },
  },

  props: {
    submissionData: {
      required: true,
      type: Array,
    },

    updateString: {
      required: true,
      type: String
    },

    selectedSkills: {
      type: Array,
      default: () => []
    },

    benchCard: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    formatLanguageColor(item) {
      let customColor = "danger"

      if (!item)
        return customColor

      let englishLevel = item.toLowerCase()

      if (englishLevel === "beginner") {
        customColor = "danger"
      }
      if (englishLevel.toLowerCase() === "intermediate") {
        customColor = "warning"
      }
      if (englishLevel.toLowerCase() === "advanced") {
        customColor = "primary"
      }
      if (englishLevel.toLowerCase() === "fluent") {
        customColor = "success"
      }

      return customColor
    },

    formatSkillsExperience(item) {
      let arr = []

      if (!item)
        return arr

      for (let [key, value] of Object.entries(item)) {
        let customColor = "primary"

        if (value.level.toLowerCase() === "expert") {
          customColor = "primary"
        }

        if (value.level.toLowerCase() === "professional") {
          customColor = "accent"
        }

        if (value.level.toLowerCase() === "experienced") {
          customColor = "secondary"
        }

        if (value.level.toLowerCase() === "aware") {
          customColor = "info"
        }

        let selectedSkillsLowerCase = this.selectedSkills.map(t => t.toLowerCase())
        if (selectedSkillsLowerCase.includes(key)) {
          customColor = "success"
        }

        arr.push({
          skill: key,
          level: value.level,
          years: value.years,
          color: customColor
        })
      }

      return arr
    },

    scoreUpdateClicked(item) {
      this.$emit("scoreItem", item)
    },

    notesButtonClicked(item) {
      this.$emit("notesItem", item)
    },

    linkTagClicked(item) {
      this.$emit("linkTagItem", item)
    },

    linkBenchClicked(item) {
      this.$emit("linkBenchItem", item)
    },

    confirmDelete(item) {
      this.$emit("confirmDeleteTalentSubmission", item)
    },

    moreInfoClicked(item) {
      this.$emit("jsonViewer", item)
    },

    clipboardCopy(email) {
      let self = this
      navigator.clipboard.writeText(email).then(function () {
        self.$store.commit("displaySnackbar", {
          message: email + " copied to clipboard",
          color: "info"
        })
      })
    },

    putColor(item, color) {
      let self = this

      this.loading = true

      item.color = color

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.put(`${this.$store.state.apiUrl}/api/talent-submission/${item.id}`, {color: color}, this.$store.state.headers)
            .then(function () {
              self.$emit("colorUpdated", item)
              self.$store.commit("displaySnackbar", {
                message: "Updated Color",
                color: "success"
              })
              self.loading = false
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Color did NOT update for some reason: " + response.response.data,
                color: "danger"
              })
              self.loading = false
            })
      });
    },

    putReminder(item) {
      let self = this

      this.loading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.put(`${this.$store.state.apiUrl}/api/talent-submission/${item.id}`, {"sendReminder": true}, this.$store.state.headers)
            .then(function (response) {
              self.$emit("stageUpdated", response.data)
              self.$store.commit("displaySnackbar", {
                message: "Reminder Sent",
                color: "success"
              })
              self.loading = false
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Reminder could not be sent: " + response.response.data,
                color: "danger"
              })
              self.loading = false
            })
      });
    },

    putData(submissionId, newStage, skillTest = "js") {
      let self = this

      if (submissionId.rate == null && newStage === "onboarding") {
        self.$store.commit("displaySnackbar", {
          message: "Cannot move user to onboarding stage: Please Set Contract Rate",
          color: "danger",
          timeout: 10000
        })
        return;
      }

      this.loading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.put(`${this.$store.state.apiUrl}/api/talent-submission/${submissionId.id}`, {"stage": newStage, "skilltest_skill": skillTest}, this.$store.state.headers)
            .then(function (response) {
              self.$emit("stageUpdated", response.data)
              self.$store.commit("displaySnackbar", {
                message: "Submission Stage Updated",
                color: "info"
              })
              self.loading = false
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: response.response.data,
                color: "danger"
              })
              self.loading = false
            })
      });
    },

    timeDiffDates(earlierDate, laterDate) {
      let oDiff = {};

      //  Calculate Differences
      //  -------------------------------------------------------------------  //
      let nTotalDiff = laterDate.getTime() - earlierDate.getTime();

      oDiff.days = Math.floor(nTotalDiff / 1000 / 60 / 60 / 24);
      nTotalDiff -= oDiff.days * 1000 * 60 * 60 * 24;

      oDiff.hours = Math.floor(nTotalDiff / 1000 / 60 / 60);
      nTotalDiff -= oDiff.hours * 1000 * 60 * 60;

      oDiff.minutes = Math.floor(nTotalDiff / 1000 / 60);
      nTotalDiff -= oDiff.minutes * 1000 * 60;

      oDiff.seconds = Math.floor(nTotalDiff / 1000);
      //  -------------------------------------------------------------------  //

      //  Format Duration
      //  -------------------------------------------------------------------  //
      //  Format Hours
      let hourtext = '00';
      if (oDiff.days > 0){ hourtext = String(oDiff.days);}
      if (hourtext.length == 1){hourtext = '0' + hourtext}

      //  Format Minutes
      let mintext = '00';
      if (oDiff.minutes > 0){ mintext = String(oDiff.minutes);}
      if (mintext.length == 1) { mintext = '0' + mintext }

      //  Format Seconds
      let sectext = '00';
      if (oDiff.seconds > 0) { sectext = String(oDiff.seconds); }
      if (sectext.length == 1) { sectext = '0' + sectext }

      //  Set Duration
      const sDuration = hourtext + ':' + mintext + ':' + sectext;
      oDiff.duration = sDuration;
      //  -------------------------------------------------------------------  //

      return oDiff.days;
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.goodGreenColor {
  background-color: rgba(0, 200, 0, 0.25);
}

.goodRedColor {
  background-color: rgba(200, 0, 0, 0.25);
}
</style>
